var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{},[(
        _vm.getSelectedRisk(_vm.impact, _vm.probability) &&
        (this.impact && this.impact.value) &&
        (this.probability && this.probability.value)
      )?_c('span',[(_vm.getSelectedRisk(_vm.impact, _vm.probability) == 'Low risk')?_c('font-awesome-icon',{staticClass:"risk-1 mr-2",attrs:{"icon":"exclamation-triangle"}}):_vm._e(),(_vm.getSelectedRisk(_vm.impact, _vm.probability) == 'Medium risk')?_c('font-awesome-icon',{staticClass:"risk-3 mr-2",attrs:{"icon":"exclamation-triangle"}}):_vm._e(),(_vm.getSelectedRisk(_vm.impact, _vm.probability) == 'High risk')?_c('font-awesome-icon',{staticClass:"risk-9 mr-2",attrs:{"icon":"exclamation-triangle"}}):_vm._e(),(_vm.getSelectedRisk(_vm.impact, _vm.probability) == 'Very high risk')?_c('font-awesome-icon',{staticClass:"risk-16 mr-2",attrs:{"icon":"exclamation-triangle"}}):_vm._e(),_vm._v("\n\n    "+_vm._s(_vm.getSelectedRisk(_vm.impact, _vm.probability))+"\n  ")],1):_vm._e(),(
          (this.impact && !this.impact.value) ||
          (this.probability && !this.probability.value)
        )?_c('span',{staticClass:"mr-1"},[_c('font-awesome-icon',{class:'risk-9',attrs:{"icon":"exclamation-triangle"}}),_vm._v("\n    "+_vm._s(_vm.$t('risk.missing_input'))+"\n  ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }