<template>
  <div>
    <!-- System table -->
    <div class="card" qid="activities-section">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5>{{ $t('systems.activities.title') }}</h5>
            <div class="mt-2">
              <span v-if="getGuide('update_activities')">
                <GuideButton :guide="getGuide('update_activities')"></GuideButton>
              </span>
            </div>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('activities.table.name') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.owners') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.tags') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.organizations') }}
            </th>
            <th scope="col">
              {{ $t('activities.table.countries') }}
            </th>
            <th scope="col">
              {{ $t('system.last_updated') }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in tableItems">
              <td>
                <router-link
                    :to="{ name: 'activities.view', params: { slug: object.slug, client: object.client }}"
                    qid="s-view-page-link"
                    v-if="object.client == getClient.slug"
                >
                  {{ object.name }}
                </router-link>
                <button v-if="object.client != getClient.slug"
                        class="btn btn-link btn-sm"
                        @click="openNoticeModal(object)"
                >
                  {{ object.name }}
                </button>
              </td>
              <td>
                <span class="mr-1" v-for="user in getReducedUsers(object.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="object.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(object)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ object.users.length - 3 }}
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="tag in getReducedTags(object.tags_translated)" qid="p-tags-list">
                  <span class="badge mb-1" :title="tag.name_translated">
                    <font-awesome-icon icon="tag"/>
                    {{ tag.name_translated | truncate(35) }}
                  </span>
                </span>
                <span v-if="object.tags_translated.length > 3"
                      class="badge cursor-pointer"
                      @click="openTagsModal(object)"
                >
                  <font-awesome-icon icon="tags"/>
                  + {{ object.tags_translated.length - 3 }}
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="organization in object.organizations" qid="p-organizations-list">
                  <span class="badge" :title="organization.name">
                    <font-awesome-icon icon="sitemap"/>
                    {{ organization.name | truncate(35) }}
                  </span>
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="country in object.countries_with_flag" qid="p-countries-list">
                  <span class="badge">
                    <img v-bind:src="country.flag" height="13" class="align-baseline mr-1" />
                    <span>{{country.name}}</span>
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
            </tr>
            <tr v-if="!sLoading && !tableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <PartitionNoticeModal
        :item="selectedItem"
        state="activities.view"
        :show="showNoticeModal"
        @closeViewModal="closeNoticeModal"
    ></PartitionNoticeModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import SystemViewModal from '@/views/Systems/SystemViewModal.vue';
  import PartitionNoticeModal from '@/components/PartitionNoticeModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'ActivitiesTable',
    data: function() {
      return {

        // Systems
        tableItems: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        pItems: [],
        sLoading: true,
        myGuides: [],

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        showSDescription: false,
        selectedItem: {},
        showNoticeModal: false,
        showTagsModal: false,
        showUsersModal: false,
      }
    },
    props: {
      system: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      SystemViewModal,
      ShowMoreModal,
      PartitionNoticeModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadActivities()
      }
    },
    methods: {
      openViewSModal(object) {
        this.selectedS = object
        this.viewSModal = true
      },
      handleCloseViewSModal () {
        this.selectedS = []
        this.viewSModal = false;
      },
      openNoticeModal(object) {
        this.selectedItem = object
        this.showNoticeModal = true
      },
      closeNoticeModal() {
        this.selectedItem = {}
        this.showNoticeModal = false
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'System'
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'System',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      loadActivities() {
        let self = this;
        piincHttp.get('systems/' + this.$route.params.slug + '/activities', { params:
          {
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.tableItems = response.data;
          self.sLoading = false;
        }, function() {});
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
    },
    created () {
      // Table items
      this.loadActivities();
    }
  }
</script>
