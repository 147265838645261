<template>
  <div>

    <div class="row">

      <!-- First card -->
      <div class="col-md-4">
        <div class="alert alert-home-box card-shadow radius-0-5" role="alert">
          <span class="alert-home-box-icon">
            <font-awesome-icon icon="search"/>
          </span>
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title">
                <span class="float-right btn-sm btn-edit mr-0"
                      qid="system-edit-description"
                      @click="showEditRRModal(system)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                {{ $t('systems.assessments.system_classification_title') }}
              </h5>
              <p class="card-text text-normal" style="min-height:80px;">
                {{ $t('systems.assessments.system_classification_description') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.overall_system_risk') }}
              </p>
              <p v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact)">
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1 mr-1'" v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact) == 'Very low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3 mr-1'" v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact) == 'Low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9 mr-1'" v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact) == 'High'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16 mr-1'" v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact) == 'Very high'"/>
                {{ getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact) }}
              </p>
              <p v-else>
                {{ $t('system.no_data') }}
              </p>
            </div>
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.business_impact_assessment') }}
              </p>
              <p v-if="getHighestImpact(system.confidentiality_impact, system.integrity_impact, system.availability_impact)">
                <span v-if="!businessImpactAssessment">
                  <font-awesome-icon icon="check-circle" class="icon-success mr-1"/>
                  {{ $t('systems.assessments.lower_than_classification') }}
                </span>
                <span v-if="businessImpactAssessment">
                  <font-awesome-icon icon="times-circle" class="icon-danger mr-1"/>
                  {{ $t('systems.assessments.higher_than_classification') }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>


      <!-- Second card -->
      <div class="col-md-4">
        <div class="alert alert-home-box card-shadow radius-0-5">
          <span class="alert-home-box-icon">
            <font-awesome-icon icon="history"/>
          </span>
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title">
                <span class="float-right btn-sm btn-edit mr-0"
                      qid="system-edit-description"
                      @click="showRPOModal(system)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                {{ $t('systems.assessments.rpo_title') }}
              </h5>
              <p class="card-text text-normal" style="min-height:80px;">
                {{ $t('systems.assessments.rpo_description') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.system_capabilities') }}
              </p>
              <p>
                <span v-if="system.rpo_number && system.rpo_type">
                  {{ system.rpo_number }} {{ system.rpo_type }}
                </span>
                <span v-if="!system.rpo_number || !system.rpo_type">
                  {{ $t('system.no_data') }}
                </span>
              </p>
            </div>
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.business_requirement') }}
              </p>
              <p v-if="system.rpo_number && system.rpo_type">
                <span v-if="!rpoBusinessRequirement">
                  <font-awesome-icon icon="check-circle" class="icon-success mr-1"/>
                  {{ $t('systems.assessments.business_requirement_met') }}
                </span>
                <span v-if="rpoBusinessRequirement">
                  <font-awesome-icon icon="times-circle" class="icon-danger mr-1"/>
                  {{ $t('systems.assessments.business_requirement_not_met') }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>


      <!-- Third card -->
      <div class="col-md-4" v-if="getClient.id && getClient.projectModule">
        <div class="alert alert-home-box card-shadow radius-0-5">
          <span class="alert-home-box-icon">
            <font-awesome-icon icon="stopwatch"/>
          </span>
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title">
                <span class="float-right btn-sm btn-edit mr-0"
                      qid="system-edit-description"
                      @click="showRTOModal(system)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                {{ $t('systems.assessments.rto_title') }}
              </h5>
              <p class="card-text text-normal" style="min-height:80px;">
                {{ $t('systems.assessments.rto_description') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.system_capabilities') }}
              </p>
              <p>
                <span v-if="system.rto_number && system.rto_type">
                  {{ system.rto_number }} {{ system.rto_type }}
                </span>
                <span v-if="!system.rto_number || !system.rto_type">
                  {{ $t('system.no_data') }}
                </span>
              </p>
            </div>
            <div class="col-sm-6">
              <p class="text-bold">
                {{ $t('systems.assessments.business_requirement') }}
              </p>
              <p v-if="system.rto_number && system.rto_type">
                <span v-if="!rtoBusinessRequirement">
                  <font-awesome-icon icon="check-circle" class="icon-success mr-1"/>
                  {{ $t('systems.assessments.business_requirement_met') }}
                </span>
                <span v-if="rtoBusinessRequirement">
                  <font-awesome-icon icon="times-circle" class="icon-danger mr-1"/>
                  {{ $t('systems.assessments.business_requirement_not_met') }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- System table -->
    <div class="card pagination-margin" qid="activities-section">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5>{{ $t('systems.assessments.title') }}</h5>
            <div class="mt-2">
              <span v-if="getGuide('update_assessments')">
                <GuideButton :guide="getGuide('update_assessments')"></GuideButton>
              </span>
            </div>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col">
                {{ $t('projects.table.name') }}
              </th>
              <th scope="col">
                {{ $t('projects.table.type') }}
              </th>
              <th scope="col">
                {{ $t('systems.assessments.table_highest_impact') }}
              </th>
              <th scope="col">
                {{ $t('systems.assessments.table_rpo') }}
              </th>
              <th scope="col">
                {{ $t('systems.assessments.table_rto') }}
              </th>
              <th scope="col" width="200">
                {{ $t('system.last_updated') }}
              </th>
              <th scope="col" width="80"></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(object, index) in tableItems" :key="index">
            <td>
              <router-link
                  :to="{ name: 'projects.view', params: { slug: object.slug }}"
                  qid="s-view-page-link"
              >
                {{ object.title }}
              </router-link>
            </td>
            <td>
              <span class="badge">
                {{ object.assessment.name }}
              </span>
            </td>
            <td>
              <span v-if="getHighestImpact(object.confidentiality, object.integrity, object.availability)">
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1 mr-1'" v-if="getHighestImpact(object.confidentiality, object.integrity, object.availability) == 'Very low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3 mr-1'" v-if="getHighestImpact(object.confidentiality, object.integrity, object.availability) == 'Low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9 mr-1'" v-if="getHighestImpact(object.confidentiality, object.integrity, object.availability) == 'High'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16 mr-1'" v-if="getHighestImpact(object.confidentiality, object.integrity, object.availability) == 'Very high'"/>
                {{ getHighestImpact(object.confidentiality, object.integrity, object.availability) }}
              </span>
            </td>
            <td>
              <span v-if="object.rpo && object.rpo.rpo_number && object.rpo.rpo_number > 0">
                {{ object.rpo.rpo_number }} {{ object.rpo.rpo_type }}
                <font-awesome-icon
                    icon="exclamation-triangle"
                    :class="'risk-9 ml-2'"
                    id="rpo-warning"
                    v-if="object.rpo_business_requirement"
                />
                <b-tooltip
                    ref="tooltip"
                    target="rpo-warning"
                >
                  {{ $t('systems.assessments.table_warning_tooltip') }}
                </b-tooltip>
              </span>
            </td>
            <td>
              <span v-if="object.rto && object.rto.rto_number && object.rto.rto_number > 0">
                {{ object.rto.rto_number }} {{ object.rto.rto_type }}
                <font-awesome-icon
                    icon="exclamation-triangle"
                    :class="'risk-9 ml-2'"
                    id="rto-warning"
                    v-if="object.rto_business_requirement"
                />
                <b-tooltip
                    ref="tooltip"
                    target="rto-warning"
                >
                  {{ $t('systems.assessments.table_warning_tooltip') }}
                </b-tooltip>
              </span>
            </td>
            <td>
              <span class="text-normal">
                {{ object.updated_at | formatDate }}
              </span>
            </td>
            <td>
              <AssessmentViewSingleTaskModal
                  :project="object"
                  :object="system"
                  v-if="isGranted([permissions.SYSTEM_VIEW, permissions.SYSTEM_VIEW_OWN], system)"
                  @reloadContent="loadContent"
              ></AssessmentViewSingleTaskModal>
            </td>
          </tr>

          <tr v-if="!sLoading && !tableItems.length" qid="no-results-row" class="text-center">
            <td colspan="10">
              <h5 class="mt-3">
                {{ $t('system.no_results') }}
              </h5>
            </td>
          </tr>

          <tr v-if="sLoading" qid="no-results-row-rr" class="text-center">
            <td colspan="10">
              <div class="mt-2 mb-4">
                <div class="swing-container">
                  <div class="swing">
                    <div class="swing-l"></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div class="swing-r"></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- System classification modal -->
    <b-modal ref="system-classification" @hide="hideEditRRModal" modal-class="task-modal mapping-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditRRModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('systems.assessments.system_classification_title') }}
          </h5>
          <div class="row mt-2 pt-4 pb-1" v-if="getGuide('system_classification')">
            <div class="col-sm-12">
              <b-form-group>
                <div class="">
                  <div v-if="getGuide('system_classification')" class="mb-2">
                    <GuideButton :guide="getGuide('system_classification')"></GuideButton>
                  </div>
                  <div v-if="wpSystemClassificationGuide && wpSystemClassificationGuide.id" class="guide-content-container">
                    <p v-if="!showFullSystemClassificationGuideDescription" v-html="wpSystemClassificationGuide.excerpt.rendered"></p>
                    <p v-if="showFullSystemClassificationGuideDescription" v-html="wpSystemClassificationGuide.content.rendered"></p>

                    <div class="text-center mt-3" @click="showFullSystemClassificationGuideDescription=!showFullSystemClassificationGuideDescription">
                      <span class="cursor-pointer" v-if="!showFullSystemClassificationGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullSystemClassificationGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="classificationFormSubmit" qid="create-ac-form">
        <div class="form-container">

          <div class="mb-3">
            <h5 class="mb-2">
              <span class="float-right btn-sm btn-edit"
                    @click="showConfidentialityForm = true"
                    qid="system-edit-description"
                    v-if="!showConfidentialityForm"
              >
                <font-awesome-icon icon="pen"/>
              </span>
              {{ $t('systems.assessments.confidentiality_title') }}
            </h5>
          </div>

          <div v-if="!showConfidentialityForm" class="mb-3">
            <label qid="create-risk-description-label">
              {{ $t('systems.assessments.risk_confidentiality') }}
            </label>
            <div class="section-container">
              <CalculatedRisk
                  :impact="confidentialityImpact == null ? [] : confidentialityImpact"
                  :probability="confidentialityProbability == null ? [] : confidentialityProbability"
              ></CalculatedRisk>
            </div>

            <label qid="create-risk-description-label" class="mt-3">
              {{ $t('systems.assessments.description_label') }}
            </label>
            <div class="section-container">
              <div v-if="form.confidentiality_description && form.confidentiality_description.length > descriptionLength">
                <p v-if="showFullConfidentialityDescription" class="text-formatted">{{ form.confidentiality_description }}</p>
                <p v-if="!showFullConfidentialityDescription" class="text-formatted">{{ form.confidentiality_description | truncate(descriptionLength) }}</p>
                <div class="text-center mt-3" @click="showFullConfidentialityDescription=!showFullConfidentialityDescription">
                  <span class="cursor-pointer" v-if="!showFullConfidentialityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullConfidentialityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="form.confidentiality_description && form.confidentiality_description.length <= descriptionLength"
                 class="text-formatted"
              >{{ form.confidentiality_description }}</p>
              <p v-if="!form.confidentiality_description" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>
          </div>
          <div v-if="showConfidentialityForm">
            <b-form-group>
              <label qid="create-risk-impact-label">
                {{ $t('processes.risk.impact_label') }}
              </label>
              <multiselect
                  v-model="confidentialityImpact"
                  :options="impactItems"
                  :placeholder="$t('processes.risk.impact_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-impact"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-risk-probability-label">
                {{ $t('processes.risk.probability_label') }}
              </label>
              <multiselect
                  v-model="confidentialityProbability"
                  :options="probabilityItems"
                  :placeholder="$t('processes.risk.probability_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-probability"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group>
              <label qid="create-risk-description-label">
                {{ $t('systems.assessments.description_label') }}
              </label>
              <b-form-textarea
                qid="risk-form-description"
                v-model="form.confidentiality_description"
                rows="4"
                max-rows="4"
                :placeholder="$t('systems.assessments.description_placeholder')"
              ></b-form-textarea>
            </b-form-group>
          </div>


          <div class="mb-3">
            <h5 class="mb-2">
              <span class="float-right btn-sm btn-edit"
                    @click="showIntegrityForm = true"
                    qid="system-edit-description"
                    v-if="!showIntegrityForm"
              >
                <font-awesome-icon icon="pen"/>
              </span>
              {{ $t('systems.assessments.integrity_title') }}
            </h5>
          </div>

          <div v-if="!showIntegrityForm" class="mb-3">
            <label qid="create-risk-description-label">
              {{ $t('systems.assessments.risk_integrity') }}
            </label>
            <div class="section-container">
              <CalculatedRisk
                  :impact="integrityImpact == null ? [] : integrityImpact"
                  :probability="integrityProbability == null ? [] : integrityProbability"
              ></CalculatedRisk>
            </div>

            <label qid="create-risk-description-label" class="mt-3">
              {{ $t('systems.assessments.description_label') }}
            </label>
            <div class="section-container">
              <div v-if="form.integrity_description && form.integrity_description.length > descriptionLength">
                <p v-if="showFullIntegrityDescription" class="text-formatted">{{ form.integrity_description }}</p>
                <p v-if="!showFullIntegrityDescription" class="text-formatted">{{ form.integrity_description | truncate(descriptionLength) }}</p>
                <div class="text-center mt-3" @click="showFullIntegrityDescription=!showFullIntegrityDescription">
                  <span class="cursor-pointer" v-if="!showFullIntegrityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullIntegrityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="form.integrity_description && form.integrity_description.length <= descriptionLength"
                 class="text-formatted"
              >{{ form.integrity_description }}</p>
              <p v-if="!form.integrity_description" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>

          </div>
          <div v-if="showIntegrityForm">
            <b-form-group>
              <label qid="create-risk-impact-label">
                {{ $t('processes.risk.impact_label') }}
              </label>
              <multiselect
                  v-model="integrityImpact"
                  :options="impactItems"
                  :placeholder="$t('processes.risk.impact_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-impact"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-risk-probability-label">
                {{ $t('processes.risk.probability_label') }}
              </label>
              <multiselect
                  v-model="integrityProbability"
                  :options="probabilityItems"
                  :placeholder="$t('processes.risk.probability_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-probability"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-risk-description-label">
                {{ $t('systems.assessments.description_label') }}
              </label>
              <b-form-textarea
                qid="risk-form-description"
                v-model="form.integrity_description"
                rows="4"
                max-rows="4"
                :placeholder="$t('systems.assessments.description_placeholder')"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="mb-3">
            <h5 class="mb-2">
              <span class="float-right btn-sm btn-edit"
                    @click="showAvailabilityForm = true"
                    qid="system-edit-description"
                    v-if="!showAvailabilityForm"
              >
                <font-awesome-icon icon="pen"/>
              </span>
              {{ $t('systems.assessments.availability_title') }}
            </h5>
          </div>

          <div v-if="!showAvailabilityForm" class="mb-3">
            <label qid="create-risk-description-label">
              {{ $t('systems.assessments.risk_availability') }}
            </label>
            <div class="section-container">
              <CalculatedRisk
                  :impact="availabilityImpact == null ? [] : availabilityImpact"
                  :probability="availabilityProbability == null ? [] : availabilityProbability"
              ></CalculatedRisk>
            </div>

            <label qid="create-risk-description-label" class="mt-3">
              {{ $t('systems.assessments.description_label') }}
            </label>
            <div class="section-container">
              <div v-if="form.availability_description && form.availability_description.length > descriptionLength">
                <p v-if="showFullAvailabilityDescription" class="text-formatted">{{ form.availability_description }}</p>
                <p v-if="!showFullAvailabilityDescription" class="text-formatted">{{ form.availability_description | truncate(descriptionLength) }}</p>
                <div class="text-center mt-3" @click="showFullAvailabilityDescription=!showFullAvailabilityDescription">
                  <span class="cursor-pointer" v-if="!showFullAvailabilityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullAvailabilityDescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="form.availability_description && form.availability_description.length <= descriptionLength"
                 class="text-formatted"
              >{{ form.availability_description }}</p>
              <p v-if="!form.availability_description" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>
          </div>
          <div v-if="showAvailabilityForm">
            <b-form-group>
              <label qid="create-risk-impact-label">
                {{ $t('processes.risk.impact_label') }}
              </label>
              <multiselect
                  v-model="availabilityImpact"
                  :options="impactItems"
                  :placeholder="$t('processes.risk.impact_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-impact"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-risk-probability-label">
                {{ $t('processes.risk.probability_label') }}
              </label>
              <multiselect
                  v-model="availabilityProbability"
                  :options="probabilityItems"
                  :placeholder="$t('processes.risk.probability_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="form-probability"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="props.option.value == 'Very unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="props.option.value == 'Unlikely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="props.option.value == 'Likely'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="props.option.value == 'Very likely'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-risk-description-label">
                {{ $t('systems.assessments.description_label') }}
              </label>
              <b-form-textarea
                qid="risk-form-description"
                v-model="form.availability_description"
                rows="4"
                max-rows="4"
                :placeholder="$t('systems.assessments.description_placeholder')"
              ></b-form-textarea>
            </b-form-group>
          </div>


        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-2 mb-2"
                            variant="secondary"
                            @click="hideEditRRModal"
                            qid="create-ac-cancel-button"
                  >
                    {{ $t('maintenance.questions.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-2 mb-2 ml-3 mr-0"
                            :disabled="buttonDisabled"
                            variant="success"
                            @click="classificationFormSubmit()"
                            qid="create-ac-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </b-modal>


    <!-- RTO modal -->
    <b-modal ref="rto-modal" @hide="hideRTOModal" modal-class="task-modal mapping-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideRTOModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('systems.assessments.rto_title') }}
          </h5>
          <div class="row mt-2 pt-4 pb-1" v-if="getGuide('assessment_rto')">
            <div class="col-sm-12">
              <b-form-group>
                <div class="">
                  <div v-if="getGuide('assessment_rto')" class="mb-2">
                    <GuideButton :guide="getGuide('assessment_rto')"></GuideButton>
                  </div>
                  <div v-if="wpRTOGuide && wpRTOGuide.id" class="guide-content-container">
                    <p v-if="!showFullRTOGuideDescription" v-html="wpRTOGuide.excerpt.rendered"></p>
                    <p v-if="showFullRTOGuideDescription" v-html="wpRTOGuide.content.rendered"></p>

                    <div class="text-center mt-3" @click="showFullRTOGuideDescription=!showFullRTOGuideDescription">
                      <span class="cursor-pointer" v-if="!showFullRTOGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullRTOGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="RTOFormSubmit" qid="create-ac-form">
        <div class="form-container">

          <div class="">
            <span class="float-right btn-sm btn-edit"
                  style="margin-top:-10px;"
                  @click="showRTOForm = true"
                  qid="system-edit-description"
                  v-if="!showRTOForm"
            >
              <font-awesome-icon icon="pen"/>
            </span>
            <label for="">
              {{ $t('systems.assessments.table_rto') }}
            </label>
          </div>


          <div v-if="!showRTOForm" class="mb-3">
            <div class="section-container">
              <span v-if="rtoNumber && rtoType && rtoType.value">
                {{ rtoNumber}} {{ rtoType.value}}
              </span>
            </div>

            <label qid="create-risk-description-label" class="mt-3">
              {{ $t('systems.assessments.description_label') }}
            </label>
            <div class="section-container">
              <div v-if="rtoDescription && rtoDescription.length > descriptionLength">
                <p v-if="showFullRTODescription" class="text-formatted">{{ rtoDescription }}</p>
                <p v-if="!showFullRTODescription" class="text-formatted">{{ rtoDescription | truncate(descriptionLength) }}</p>
                <div class="text-center mt-3" @click="showFullRTODescription=!showFullRTODescription">
                  <span class="cursor-pointer" v-if="!showFullRTODescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullRTODescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="rtoDescription && rtoDescription.length <= descriptionLength"
                 class="text-formatted"
              >{{ rtoDescription }}</p>
              <p v-if="!rtoDescription" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>
          </div>

          <div v-if="showRTOForm">
            <div class="row mb-3">
              <div class="col-sm-6">
                <b-form-input
                  type="number"
                  min="0"
                  v-model="rtoNumber"
                  :placeholder="$t('systems.assessments.rpo_number_placeholder')"
                  qid="create-p-links"
                ></b-form-input>
              </div>
              <div class="col-sm-6">
                <multiselect
                    v-model="rtoType"
                    :options="timeItems"
                    :placeholder="$t('systems.assessments.rpo_type_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="form-impact"
                ></multiselect>
              </div>
            </div>

            <b-form-group>
              <label qid="create-risk-description-label">
                {{ $t('systems.assessments.description_label') }}
              </label>
              <b-form-textarea
                qid="risk-form-description"
                v-model="rtoDescription"
                rows="4"
                max-rows="4"
                :placeholder="$t('systems.assessments.description_placeholder')"
              ></b-form-textarea>
            </b-form-group>
          </div>


        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-2 mb-2"
                            variant="secondary"
                            @click="hideRTOModal"
                            qid="create-ac-cancel-button"
                  >
                    {{ $t('maintenance.questions.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-2 mb-2 ml-3 mr-0"
                            :disabled="buttonDisabled"
                            variant="success"
                            @click="RTOFormSubmit()"
                            qid="create-ac-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </b-modal>


    <!-- RPO modal -->
    <b-modal ref="rpo-modal" @hide="hideRPOModal" modal-class="task-modal mapping-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideRPOModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('systems.assessments.rpo_title') }}
          </h5>
          <div class="row mt-2 pt-4 pb-1" v-if="getGuide('assessment_rpo')">
            <div class="col-sm-12">
              <b-form-group>
                <div class="">
                  <div v-if="getGuide('assessment_rpo')" class="mb-2">
                    <GuideButton :guide="getGuide('assessment_rpo')"></GuideButton>
                  </div>
                  <div v-if="wpRPOGuide && wpRPOGuide.id" class="guide-content-container">
                    <p v-if="!showFullRPOGuideDescription" v-html="wpRPOGuide.excerpt.rendered"></p>
                    <p v-if="showFullRPOGuideDescription" v-html="wpRPOGuide.content.rendered"></p>

                    <div class="text-center mt-3" @click="showFullRPOGuideDescription=!showFullRPOGuideDescription">
                      <span class="cursor-pointer" v-if="!showFullRPOGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullRPOGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="RPOFormSubmit" qid="create-ac-form">
        <div class="form-container">

          <div class="">
            <span class="float-right btn-sm btn-edit"
                  style="margin-top:-10px;"
                  @click="showRPOForm = true"
                  qid="system-edit-description"
                  v-if="!showRPOForm"
            >
              <font-awesome-icon icon="pen"/>
            </span>
            <label for="">
              {{ $t('systems.assessments.table_rpo') }}
            </label>
          </div>

          <div v-if="!showRPOForm" class="mb-3">
            <div class="section-container">
              <span v-if="rpoNumber && rpoType && rpoType.value">
                {{ rpoNumber}} {{ rpoType.value}}
              </span>
            </div>

            <label qid="create-risk-description-label" class="mt-3">
              {{ $t('systems.assessments.description_label') }}
            </label>
            <div class="section-container">
              <div v-if="rpoDescription && rpoDescription.length > descriptionLength">
                <p v-if="showFullRPODescription" class="text-formatted">{{ rpoDescription }}</p>
                <p v-if="!showFullRPODescription" class="text-formatted">{{ rpoDescription | truncate(descriptionLength) }}</p>
                <div class="text-center mt-3" @click="showFullRPODescription=!showFullRPODescription">
                  <span class="cursor-pointer" v-if="!showFullRPODescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullRPODescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="rpoDescription && rpoDescription.length <= descriptionLength"
                 class="text-formatted"
              >{{ rpoDescription }}</p>
              <p v-if="!rpoDescription" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>
          </div>

          <div v-if="showRPOForm">
            <div class="row mb-3">
              <div class="col-sm-6">
                <b-form-input
                  type="number"
                  min="0"
                  v-model="rpoNumber"
                  :placeholder="$t('systems.assessments.rpo_number_placeholder')"
                  qid="create-p-links"
                ></b-form-input>
              </div>
              <div class="col-sm-6">
                <multiselect
                    v-model="rpoType"
                    :options="timeItems"
                    :placeholder="$t('systems.assessments.rpo_type_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="form-impact"
                ></multiselect>
              </div>
            </div>

            <b-form-group>
              <label qid="create-risk-description-label">
                {{ $t('systems.assessments.description_label') }}
              </label>
              <b-form-textarea
                qid="risk-form-description"
                v-model="rpoDescription"
                rows="4"
                max-rows="4"
                :placeholder="$t('systems.assessments.description_placeholder')"
              ></b-form-textarea>
            </b-form-group>

          </div>

        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-2 mb-2"
                            variant="secondary"
                            @click="hideRPOModal"
                            qid="create-ac-cancel-button"
                  >
                    {{ $t('maintenance.questions.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-2 mb-2 ml-3 mr-0"
                            :disabled="buttonDisabled"
                            variant="success"
                            @click="RPOFormSubmit()"
                            qid="create-ac-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </b-modal>


    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <PartitionNoticeModal
        :item="selectedItem"
        state="activities.view"
        :show="showNoticeModal"
        @closeViewModal="closeNoticeModal"
    ></PartitionNoticeModal>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import PartitionNoticeModal from '@/components/PartitionNoticeModal.vue';
  import AssessmentViewSingleTaskModal from '@/views/Tasks/AssessmentViewSingleTaskModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';
  import CalculatedRisk from '@/views/RiskScenarios/CalculatedRisk.vue';
  import {config} from '@/config.js'


  export default {
    name: 'AssessmentsTable',
    data: function() {
      return {

        // Systems
        tableItems: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        pItems: [],
        sLoading: true,
        myGuides: [],
        buttonDisabled: false,
        system: {},
        descriptionLength: config.descriptionLength,

        showFullConfidentialityDescription: false,
        showFullIntegrityDescription: false,
        showFullAvailabilityDescription: false,

        showConfidentialityForm: false,
        showIntegrityForm: false,
        showAvailabilityForm: false,

        showRPOForm: false,
        showRTOForm: false,
        showFullRPODescription: false,
        showFullRTODescription: false,

        showFullSystemClassificationGuideDescription: false,
        showFullRPOGuideDescription: false,
        showFullRTOGuideDescription: false,
        wpRPOGuide: {},
        wpRTOGuide: {},
        wpSystemClassificationGuide: {},

        confidentialityImpact: [],
        confidentialityProbability: [],
        integrityImpact: [],
        integrityProbability: [],
        availabilityImpact: [],
        availabilityProbability: [],
        confidentialityDescription: '',
        integrityDescription: '',
        availabilityDescription: '',

        rpoNumber: '',
        rpoType: [],
        rpoDescription: '',
        rtoNumber: '',
        rtoType: [],
        rtoDescription: '',
        rtoBusinessRequirement: '',
        rpoBusinessRequirement: '',
        businessImpactAssessment: '',

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        showSDescription: false,
        selectedItem: {},
        showNoticeModal: false,
        showTagsModal: false,
        showUsersModal: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        impactItems: [
          { name: this.$t('impact.very_low'), value: 'Very low' },
          { name: this.$t('impact.low'), value: 'Low' },
          { name: this.$t('impact.high'), value: 'High' },
          { name: this.$t('impact.very_high'), value: 'Very high' }
        ],
        probabilityItems: [
          { name: this.$t('probability.very_unlikely'), value: 'Very unlikely' },
          { name: this.$t('probability.unlikely'), value: 'Unlikely' },
          { name: this.$t('probability.likely'), value: 'Likely' },
          { name: this.$t('probability.very_likely'), value: 'Very likely' }
        ],
        timeItems: [
          { name: this.$t('system.interval_seconds'), value: 'Seconds' },
          { name: this.$t('system.interval_minutes'), value: 'Minutes' },
          { name: this.$t('system.interval_hours'), value: 'Hours' },
          { name: this.$t('system.interval_days'), value: 'Days' },
          { name: this.$t('system.interval_months'), value: 'Months' },
          { name: this.$t('system.interval_years'), value: 'Years' },
        ],
        form: {
          confidentiality_impact: '',
          confidentiality_probability: '',
          confidentiality_description: '',
          integrity_impact: '',
          integrity_probability: '',
          integrity_description: '',
          availability_impact: '',
          availability_probability: '',
          availability_description: ''
        }
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      ShowMoreModal,
      PartitionNoticeModal,
      AssessmentViewSingleTaskModal,
      GuideButton,
      CalculatedRisk
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      getWordpressLink() {
        return this.$store.state.wordpressLink
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContent()
      },
      object: function (value) {
        if (value && value.id) {
          this.system = value
        }
      },
    },
    methods: {
      pageChanged(page) {
        this.loadContent(page)
      },
      perPageChanged(perPage) {
        let self = this;
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load content when page changed listener is triggered
        this.loadContent(1, perPage.value)
      },
      getHighestImpact(confidentialityParam, integrityParam, availabilityParam) {

        // Variable definitions
        let array = []
        let sortedArray = []
        let highestImpact = []
        let confidentiality = {}
        let integrity = {}
        let availability = {}

        // If confidentiality is existing as parameter
        if (confidentialityParam) {
          // Construct custom object with value number (for comparation)
          confidentiality.name = this.$t('systems.assessments.confidentiality_label')
          confidentiality.label = confidentialityParam
          confidentiality.value = _.findIndex(this.impactItems, function(o) { return o.value == confidentialityParam; });
          array.push(confidentiality)
        }
        // If integrity is existing as parameter
        if (integrityParam) {
          // Construct custom object with value number (for comparation)
          integrity.name = this.$t('systems.assessments.integrity_label')
          integrity.label = integrityParam
          // Value is index in risk types array (sorted from low to higher)
          integrity.value = _.findIndex(this.impactItems, function(o) { return o.value == integrityParam; });
          array.push(integrity)
        }
        // If availability is existing as parameter
        if (availabilityParam) {
          // Construct custom object with value number (for comparation)
          availability.name = this.$t('systems.assessments.availability_label')
          availability.label = availabilityParam
          // Value is index in risk types array (sorted from low to higher)
          availability.value = _.findIndex(this.impactItems, function(o) { return o.value == availabilityParam; });
          array.push(availability)
        }

        // If we have at least one item (confidentiality, integrity or availability)
        if (array && array.length) {
          // Sort array by it's value (lower to higher risk)
          sortedArray = _.sortBy(array, 'value');
          // Reverse array to be from highest to lower risk
          _.reverse(sortedArray);
          // Set highes impact object by taking the first item of the array which has the biggest risk
          highestImpact = sortedArray[0]

          return highestImpact.label;
        } else {
          return false
        }

      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContent(1, self.selectedPerPage)
      },
      openViewSModal(object) {
        this.selectedS = object
        this.viewSModal = true
      },
      handleCloseViewSModal () {
        this.selectedS = []
        this.viewSModal = false;
      },
      openNoticeModal(object) {
        this.selectedItem = object
        this.showNoticeModal = true
      },
      closeNoticeModal() {
        this.selectedItem = {}
        this.showNoticeModal = false
      },
      showEditRRModal(object) {
        let self = this;
        this.error = ''

        this.showConfidentialityForm = object.confidentiality_impact && object.confidentiality_probability ? false : true
        this.showIntegrityForm = object.integrity_impact && object.integrity_probability ? false : true
        this.showAvailabilityForm = object.availability_impact && object.availability_probability ? false : true

        this.showFullConfidentialityDescription = false;
        this.showFullIntegrityDescription = false;
        this.showFullAvailabilityDescription = false;

        // Impact
        this.confidentialityImpact = []
        if (object.confidentiality_impact) {
          this.confidentialityImpact = _.find(this.impactItems, function(item) {
            return item.value === object.confidentiality_impact;
          });
        }

        this.integrityImpact = []
        if (object.integrity_impact) {
          this.integrityImpact = _.find(this.impactItems, function(item) {
            return item.value === object.integrity_impact;
          });
        }

        this.availabilityImpact = []
        if (object.availability_impact) {
          this.availabilityImpact = _.find(this.impactItems, function(item) {
            return item.value === object.availability_impact;
          });
        }

        // Probability
        this.confidentialityProbability = []
        if (object.confidentiality_probability) {
          this.confidentialityProbability = _.find(this.probabilityItems, function(item) {
            return item.value === object.confidentiality_probability;
          });
        }

        this.integrityProbability = []
        if (object.integrity_probability) {
          this.integrityProbability = _.find(this.probabilityItems, function(item) {
            return item.value === object.integrity_probability;
          });
        }

        this.availabilityProbability = []
        if (object.availability_probability) {
          this.availabilityProbability = _.find(this.probabilityItems, function(item) {
            return item.value === object.availability_probability;
          });
        }

        this.form.slug = object.slug;
        this.form.confidentiality_description = object.confidentiality_description;
        this.form.integrity_description = object.integrity_description;
        this.form.availability_description = object.availability_description;
        this.$refs['system-classification'].show()
      },
      hideEditRRModal() {
        this.error = ''
        this.confidentialityImpact = []
        this.integrityImpact = []
        this.availabilityImpact = []

        this.confidentialityProbability = []
        this.integrityProbability = []
        this.availabilityProbability = []

        this.form.confidentiality_description = ''
        this.form.integrity_description = ''
        this.form.availability_description = ''
        this.$refs['system-classification'].hide()
      },
      classificationFormSubmit(evt) {
        this.buttonDisabled = true;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (evt) {
          evt.preventDefault();
        }

        let form = {};

        form.confidentiality_description = this.form.confidentiality_description
        form.integrity_description = this.form.integrity_description
        form.availability_description = this.form.availability_description

        if (this.confidentialityImpact && this.confidentialityImpact.value) {
          form.confidentiality_impact = this.confidentialityImpact.value
        }
        if (this.integrityImpact && this.integrityImpact.value) {
          form.integrity_impact = this.integrityImpact.value
        }
        if (this.availabilityImpact && this.availabilityImpact.value) {
          form.availability_impact = this.availabilityImpact.value
        }

        if (this.confidentialityProbability && this.confidentialityProbability.value) {
          form.confidentiality_probability = this.confidentialityProbability.value
        }
        if (this.integrityProbability && this.integrityProbability.value) {
          form.integrity_probability = this.integrityProbability.value
        }
        if (this.availabilityProbability && this.availabilityProbability.value) {
          form.availability_probability = this.availabilityProbability.value
        }

        piincHttp.put('systems/' + this.system.slug, form).then(function(response) {
          self.$toastr('success', self.$t('systems.assessments.system_classification_success'))
          self.hideEditRRModal();
          self.loadSystem();
          self.loadContent(self.currentPage, self.selectedPerPage);
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      showRPOModal(object) {
        let self = this;
        this.error = ''

        // Impact
        this.rpoType = []
        if (object.rpo_type) {
          this.rpoType = _.find(this.timeItems, function(item) {
            return item.value === object.rpo_type;
          });
        } else {
          this.rpoType = this.timeItems[3];
        }

        this.showRPOForm = object.rpo_number && object.rpo_number ? false : true
        this.showFullRPODescription = false;

        this.form.slug = object.slug;
        this.rpoNumber = object.rpo_number;
        this.rpoDescription = object.rpo_description;

        this.$refs['rpo-modal'].show()
      },
      hideRPOModal() {
        this.error = ''

        this.rpoType = []

        this.rpoNumber = '';
        this.rpoDescription = '';
        this.$refs['rpo-modal'].hide()
      },
      RPOFormSubmit(evt) {
        this.buttonDisabled = true;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (evt) {
          evt.preventDefault();
        }

        let form = {};

        form.rpo_number = this.rpoNumber
        form.rpo_description = this.rpoDescription

        if (this.rpoType && this.rpoType.value) {
          form.rpo_type = this.rpoType.value
        }

        piincHttp.put('systems/' + this.system.slug, form).then(function(response) {
          self.$toastr('success', self.$t('systems.assessments.rpo_success'))
          self.hideRPOModal();
          self.loadSystem();
          self.loadContent(self.currentPage, self.selectedPerPage);
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      showRTOModal(object) {
        let self = this;
        this.error = ''

        // Impact
        this.rtoType = []
        if (object.rto_type) {
          this.rtoType = _.find(this.timeItems, function(item) {
            return item.value === object.rto_type;
          });
        } else {
          this.rtoType = this.timeItems[3];
        }

        this.showRTOForm = object.rto_number && object.rto_number ? false : true
        this.showFullRTODescription = false;

        this.form.slug = object.slug;
        this.rtoNumber = object.rto_number;
        this.rtoDescription = object.rto_description;

        this.$refs['rto-modal'].show()
      },
      hideRTOModal() {
        this.error = ''

        this.rtoType = []

        this.rtoNumber = '';
        this.rtoDescription = '';
        this.$refs['rto-modal'].hide()
      },
      RTOFormSubmit(evt) {
        this.buttonDisabled = true;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (evt) {
          evt.preventDefault();
        }

        let form = {};

        form.rto_number = this.rtoNumber
        form.rto_description = this.rtoDescription

        if (this.rtoType && this.rtoType.value) {
          form.rto_type = this.rtoType.value
        }

        piincHttp.put('systems/' + this.system.slug, form).then(function(response) {
          self.$toastr('success', self.$t('systems.assessments.rto_success'))
          self.hideRTOModal();
          self.loadSystem();
          self.loadContent(self.currentPage, self.selectedPerPage);
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'System' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'System',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
            piincHttp.get(self.getWordpressLink + self.myGuides[0].external_id).then(function(response) {

              if (code == 'assessment_rpo') {
                self.wpRPOGuide = response.data
              }
              if (code == 'assessment_rto') {
                self.wpRTOGuide = response.data
              }
              if (code == 'system_classification') {
                self.wpSystemClassificationGuide = response.data
              }
              if (code == 'system_assessment') {
                self.wpSystemAssessmentGuide = response.data
              }

            }, function() {});
          }
        }, function() {});
      },
      loadActivities() {
        let self = this;
        piincHttp.get('systems/' + this.$route.params.slug + '/activities', { params:
          {
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.tableItems = response.data;
          self.sLoading = false;
        }, function() {});
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      loadSystem() {
        let self = this;
        piincHttp.get('systems/' + this.system.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.system = response.data;
        }, function() {});
      },
      loadContent(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('systems/' + this.$route.params.slug + '/assessments', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.sLoading = false;
          self.tableItems = response.data.projects.items;
          // Set global values for card labels
          self.rtoBusinessRequirement = response.data.rto_business_requirement;
          self.rpoBusinessRequirement = response.data.rpo_business_requirement;
          self.businessImpactAssessment = response.data.business_impact_assessment;
          // Set number of total items
          self.totalRows = response.data.projects.total_count;
        }, function() {});
      },
    },
    created () {
      this.system = this.object
      // Table items
      this.loadContent();

      this.loadGuides('assessment_rpo');
      this.loadGuides('assessment_rto');
      this.loadGuides('system_classification');
    }
  }
</script>
