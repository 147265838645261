<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <!-- Basic info block -->
      <BasicInfoBlock :object="system" @updated="updateSystem" :edit="edit"></BasicInfoBlock>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('systems.tab.basic_info')" active @click="basicTabClicked">
          <!-- Description block -->
          <br />
          <DescriptionBlock :object="system" @updated="updateSystem"></DescriptionBlock>
          <br />
          <!-- Policies list -->
          <PoliciesAndProceduresTable :object="system" @updated="updateSystem" v-if="getClient.policyModule"></PoliciesAndProceduresTable>

        </b-tab>


        <!-- Controls tab -->
        <b-tab :title="$t('systems.tab.controls')" class="mt-3" @click="controlsTabClicked">
          <div v-if="showControlsTab">
            <MeasuresTable :object="system" @updated="updateSystem"></MeasuresTable>
            <br>
          </div>
        </b-tab>

        <!-- Risk scenarios tab -->
        <b-tab :title="$t('systems.tab.risk_management')" class="mt-3" @click="riskTabClicked" v-if="getClient.riskModule">
          <div v-if="showRiskTab">
            <RiskScenariosTable :object="system" @updated="updateSystem"></RiskScenariosTable>
          </div>
        </b-tab>


        <!-- System assesments tab -->
        <b-tab :title="$t('systems.tab.assessments')" class="mt-3" @click="assessmentsTabClicked" v-if="getClient.assessmentModule">
          <AssessmentsTable :object="system" @updated="updateSystem"></AssessmentsTable>
        </b-tab>


        <!-- Relations  tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.relations')" class="sub-menu" @click="dataTabClicked">
          <b-tabs class="menu-smaller" v-model="subMenuIndex">
            <!-- Data tab -->
            <b-tab :title="$t('systems.tab.data')" class="mt-3" @click="dataTabClicked">
              <div v-if="showDataTab">
                <!-- Data objects table -->
                <DataObjectsTable :object="system" @updated="updateSystem"></DataObjectsTable>
              </div>
            </b-tab>
            <!-- System activities tab -->
            <b-tab :title="$t('systems.tab.activities')" class="mt-3" @click="activitiesTabClicked">
              <ActivitiesTable :system="system" @updated="updateSystem"></ActivitiesTable>
            </b-tab>
            <!-- System Third parties tab -->
            <b-tab :title="$t('systems.tab.third_party')" class="mt-3" v-if="getClient.contractBasicModule">
              <ThirdPartiesTable :object="system" @updated="updateSystem"></ThirdPartiesTable>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- System history tab -->
        <b-tab :title="$t('systems.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <SystemHistory :object="system"></SystemHistory>
          </div>
        </b-tab>

      </b-tabs>


      <ShowMoreModal
          :items="selectedItem.tags_translated"
          :show="showTagsModal"
          @closeViewModal="closeTagsModal"
          type="tags"
      ></ShowMoreModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import SystemEditForm from '@/views/Systems/SystemEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import LegalBasisViewModal from '@/views/Processes/LegalBasisViewModal.vue';
import LegalBasisEditModal from '@/views/Processes/LegalBasisEditModal.vue';
import DataDiscoveryTable from '@/views/Systems/DataDiscoveryTable.vue';
import DescriptionBlock from '@/views/Systems/DescriptionBlock.vue';
import PoliciesAndProceduresTable from '@/views/Systems/PoliciesAndProceduresTable.vue';
import BasicInfoBlock from '@/views/Systems/BasicInfoBlock.vue';
import ThirdPartiesTable from '@/views/Systems/ThirdPartiesTable.vue';
import DataObjectsTable from '@/views/Systems/DataObjectsTable.vue';
import MeasuresTable from '@/views/Systems/MeasuresTable.vue';
import AccessControlTable from '@/views/Systems/AccessControlTable.vue';
import NetworkTable from '@/views/Systems/NetworkTable.vue';
import LogManagementTable from '@/views/Systems/LogManagementTable.vue';
import AssessmentsTable from '@/views/Systems/AssessmentsTable.vue';
import ActivitiesTable from '@/views/Systems/ActivitiesTable.vue';
import SystemHistory from '@/views/Systems/SystemHistory.vue';
import RiskScenariosTable from '@/views/Systems/RiskScenariosTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'SystemView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('systems.title'),
          to: '/systems'
        }
      ],
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      system: {},
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,

      itemToDelete: {},
      tags: [],
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedArticle: [],
      articles: [],
      selectedFiles: [],
      showArticleDescription: false,
      showDescriptionInput: false,
      showPurposeInput: false,
      DOTableItems: [],
      DOLoading: false,
      DOItems: [],
      selectedDO: [],
      object: {},
      tabIndex: 0,
      subMenuIndex: 0,

      // Tabs
      showControlsTab: false,
      showHistoryTab: false,
      showRiskTab: false,
      showAssessmentsTab: false,
      showDataTab: false,

      buttonDisabled: false,
      myTasks: [],
      myGuides: [],

      // Pagination
      perPageOptions: [
        { name: '10', value: '10' },
        { name: '20', value: '20' },
        { name: '50', value: '50' },
        { name: '100', value: '100' }
      ],
      perPage: '',
      selectedPerPage: "10",
      currentPage: 1,
      totalRows: 0,
      keywordValue: '',
      selectedDataObject: {},
      viewDataObjectActivitiesModal: false,
      selectedDataObjectActivities: [],
      selectedItem: {},
      showTagsModal: false,
      viewDataModal: false,
      show404: false,
      show403: false,
    }
  },
  components: {
    SystemEditForm,
    Avatar,
    DescriptionBlock,
    LegalBasisViewModal,
    LegalBasisEditModal,
    AccessControlTable,
    LogManagementTable,
    NetworkTable,
    DataDiscoveryTable,
    ActivitiesTable,
    SystemHistory,
    RiskScenariosTable,
    ShowMoreModal,
    AssessmentsTable,
    PoliciesAndProceduresTable,
    ThirdPartiesTable,
    DataObjectsTable,
    BasicInfoBlock,
    MeasuresTable,
    page403,
    page404
  },
  computed: {
    rows() {
      // Use computed value for rows because of dynamic update
      return this.totalRows
    },
    getClient() {
      return this.$store.state.client
    },
    getFiles() {
      return this.selectedFiles
    },
    getTabIndex() {
      return this.$store.state.tabIndex
    },
    getPerPage() {
      let page = this.currentPage;
      let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
      let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
      let selectedOf = this.totalRows;

      if(selectedTo > selectedOf){
        selectedTo = selectedOf
      }

      return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadPage() {
      return this.$store.state.updatePage
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      let self = this;
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        setTimeout(function(){
          if (self.$route.name == 'systems.view') {
            self.goToList()
          }
        }, 800);
      } else {
        this.loadContent(true);
        this.loadArticles();
      }
    },
    getReloadContent: function () {
      if (!this.showOnTask) {
        if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
          if (this.$store.state.updatedObject.id == this.system.id && this.$store.state.updatedObject.who_am_i == this.system.who_am_i) {
            this.system = this.$store.state.updatedObject
          }
        }
      }
    },
    getReloadPage: function () {
      if (this.$store.state.updatedObject && this.$store.state.updatedObject.id && this.$store.state.updatedObject.id == this.system.id && this.$store.state.updatedObject.who_am_i == this.system.who_am_i) {
        this.$router.push({ name: 'systems.view', params: { slug: this.$store.state.updatedObject.slug }, query: { task: this.$route.query.task }})
      } else {
        this.$router.push({ name: 'systems.view', params: { slug: this.system.slug }, query: { task: this.$route.query.task }})
      }
    },
    getTabIndex: function (newValue, oldValue) {
      this.handleUpdateTab(newValue)
    }
  },
  methods: {
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    openToolbar(object) {
      this.$store.commit('storeTaskToolbarState', true);
      this.$store.commit('storeActiveTask', object);
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'System' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'System',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    openHelpCenter(object) {
      let topElement = document.getElementById("app");
      if (topElement.classList.contains("guide-collapsed")) {
        topElement.classList.toggle("guide-collapsed");
      }
      if (!topElement.classList.contains("activity-collapsed")) {
        topElement.classList.toggle("activity-collapsed");
      }

      this.$store.commit('storeHelpCenterState', true);
      this.$store.commit('storeActiveGuideScope', object);
    },
    historyTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = true;
      this.showDataTab = false;
      this.showRiskTab = false;
    },
    controlsTabClicked() {
      this.showAssessmentsTab = false;
      this.showControlsTab = true;
      this.showHistoryTab = false;
      this.showDataTab = false;
      this.showRiskTab = false;
    },
    basicTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = false;
      this.showDataTab = false;
      this.showRiskTab = false;
    },
    riskTabClicked() {
      this.showAssessmentsTab = false;
      this.showRiskTab = true;
      this.showDataTab = false;
      this.showHistoryTab = false;
    },
    activitiesTabClicked() {
      this.showHistoryTab = false;
      this.showRiskTab = false;
      this.showDataTab = false;
    },
    assessmentsTabClicked() {
      this.showAssessmentsTab = true;
      this.showHistoryTab = false;
      this.showDataTab = false;
      this.showRiskTab = false;
    },
    dataTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = false;
      this.showDataTab = true;
      this.showRiskTab = false;
    },
    goToList() {
      this.$router.push('/systems')
    },
    handleUpdateTab(response) {
      if (response === 'data') {
        let index = 4;

        if (!this.getClient.assessmentModule) {
          index = index - 1;
        }

        if (!this.getClient.riskModule) {
          index = index - 1;
        }

        this.tabIndex = index
        this.dataTabClicked()
      }
      if (response === 'relations-third-parties') {
        let index = 4;

        if (!this.getClient.assessmentModule) {
          index = index - 1;
        }

        if (!this.getClient.riskModule) {
          index = index - 1;
        }

        this.tabIndex = index
        this.dataTabClicked()
        this.subMenuIndex = 2
      }
      if (response === 'controls') {
        this.tabIndex = 1
        this.controlsTabClicked()
      }
      if (response === 'risk-management') {
        this.tabIndex = 2
        this.riskTabClicked()
      }
      if (response === 'basic-info') {
        this.tabIndex = 0
        this.basicTabClicked()
      }
    },
    updateSystem() {
      this.loadContent();
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    onFileChange() {
      let files = document.querySelector('#file-lb');
      // in order for v-for to re render, we need to set array to empty
      this.selectedFiles = [];
      // And then push item by item into that array
      for (var i = 0; i < files.files.length; i++) {
        this.selectedFiles.push(files.files[i])
      }
    },
    handleLink() {
      // If there's a link in input, add it to links array
      if (this.inputLink !== '') {
        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }
        this.lbForm.links.push(this.inputLink)
        // Reset link input form
        this.inputLink = ''
      }
    },
    removeLinkItem(index) {
      // Remove link item from the links array
      this.lbForm.links.splice(index, 1);
    },
    reloadContent(object) {
      // If slug is not changed, just reload content
      if (object.slug == this.system.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'systems.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    loadArticles(query) {
      let self = this;
      piincHttp.get('articles', { params:
        {
          client: this.getClient.slug,
          per_page: 100,
          keyword: query ? query : undefined
        }
      }).then(function(response) {
        self.articles = response.data.items
        if (self.articles && !self.articles.length && !query) {
          self.articles.push({name: self.$t('system.no_results'), $isDisabled: true})
        }
      }, function() {});
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('systems/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug,
        }
      }).then(function(response) {
        self.system = response.data;
        // Push Third party name in breadcrumb array
        if (breadcrumbPush) {
          self.breadcrumbItems.push({text: self.system.name, active: true});
        }
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.system.who_am_i,
            model_id: this.system.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('storeTabIndex', '');
    next()
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
      this.loadArticles();
    }

    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
        if (self.tabIndex == 1) {
          self.controlsTabClicked()
        }
        if (self.tabIndex == 2) {
          self.riskTabClicked()
        }
      }, 1000);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 3000);
    }

    this.loadGuides('update_basic_info');
  }
}
</script>
