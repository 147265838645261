<template>
  <span class="">

    <!-- Below tolerance -->
    <span
        v-if="
          getSelectedRisk(impact, probability) &&
          (this.impact && this.impact.value) &&
          (this.probability && this.probability.value)
        "
    >
      <font-awesome-icon icon="exclamation-triangle" class="risk-1 mr-2" v-if="getSelectedRisk(impact, probability) == 'Low risk'"/>
      <font-awesome-icon icon="exclamation-triangle" class="risk-3 mr-2" v-if="getSelectedRisk(impact, probability) == 'Medium risk'"/>
      <font-awesome-icon icon="exclamation-triangle" class="risk-9 mr-2" v-if="getSelectedRisk(impact, probability) == 'High risk'"/>
      <font-awesome-icon icon="exclamation-triangle" class="risk-16 mr-2" v-if="getSelectedRisk(impact, probability) == 'Very high risk'"/>

      {{ getSelectedRisk(impact, probability) }}
    </span>

    <!-- Missing input -->
    <span class="mr-1"
          v-if="
            (this.impact && !this.impact.value) ||
            (this.probability && !this.probability.value)
          "
    >
      <font-awesome-icon
          icon="exclamation-triangle"
          :class="'risk-9'"
      />
      {{ $t('risk.missing_input') }}
    </span>

  </span>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'CalculatedRisk',
    data: function() {
      return {
        tolerance: {}
      }
    },
    props: {
      impact: {
        type: [Object, Array]
      },
      probability: {
        type: [Object, Array]
      },
      riskTolerance: {},
      riskCategory: {}
    },
    components: {

    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      getSelectedRisk(impact, probability) {
        if (impact.value && impact.value == 'Very high' && probability.value && probability.value == 'Very unlikely') {
          return 'Medium risk'
        }
        if (impact.value && impact.value == 'Very high' && probability.value && probability.value == 'Unlikely') {
          return 'High risk'
        }
        if (impact.value && impact.value == 'Very high' && probability.value && probability.value == 'Likely') {
          return 'Very high risk'
        }
        if (impact.value && impact.value == 'Very high' && probability.value && probability.value == 'Very likely') {
          return 'Very high risk'
        }

        if (impact.value && impact.value == 'High' && probability.value && probability.value == 'Very unlikely') {
          return 'Medium risk'
        }
        if (impact.value && impact.value == 'High' && probability.value && probability.value == 'Unlikely') {
          return 'High risk'
        }
        if (impact.value && impact.value == 'High' && probability.value && probability.value == 'Likely') {
          return 'High risk'
        }
        if (impact.value && impact.value == 'High' && probability.value && probability.value == 'Very likely') {
          return 'Very high risk'
        }

        if (impact.value && impact.value == 'Low' && probability.value && probability.value == 'Very unlikely') {
          return 'Low risk'
        }
        if (impact.value && impact.value == 'Low' && probability.value && probability.value == 'Unlikely') {
          return 'Medium risk'
        }
        if (impact.value && impact.value == 'Low' && probability.value && probability.value == 'Likely') {
          return 'High risk'
        }
        if (impact.value && impact.value == 'Low' && probability.value && probability.value == 'Very likely') {
          return 'High risk'
        }

        if (impact.value && impact.value == 'Very low' && probability.value && probability.value == 'Very unlikely') {
          return 'Low risk'
        }
        if (impact.value && impact.value == 'Very low' && probability.value && probability.value == 'Unlikely') {
          return 'Low risk'
        }
        if (impact.value && impact.value == 'Very low' && probability.value && probability.value == 'Likely') {
          return 'Medium risk'
        }
        if (impact.value && impact.value == 'Very low' && probability.value && probability.value == 'Very likely') {
          return 'Medium risk'
        }
      }
    },
    created () {

    }
  }
</script>
