<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <!--<h6 class="text-muted m-3 text-center">ACTIVITY FEED</h6>-->
          <b-list-group>
            <b-list-group-item
                v-for="activity in items"
                :key="activity.id"
                class="border-left-0 border-right-0 border-top border-bottom-0 pt-4 pb-4"
            >

              <SystemActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\System'"/>
              <SystemPolicyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemPolicy'"/>
              <SystemNetworkActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemNetwork'"/>
              <SystemLogManagementActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemLogManagement'"/>
              <SystemDataObjectActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemDataObject'"/>
              <SystemDataDiscoveryActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemDataDiscovery'"/>
              <SystemAccessControlActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemAccessControl'"/>
              <SystemThirdPartyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemThirdParty'"/>
              <SystemValidationActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Validation\\Models\\Validation'"/>
              <SystemRiskScenarioActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemRiskScenario'"/>
              <SystemMeasureActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\System\\Models\\SystemMeasure'"/>
            </b-list-group-item>

          </b-list-group>
          <hr class="mt-0"/>
        </div>
        <InfiniteLoading @infinite="loadingHandler">
          <template slot="no-results">
            <span>
              {{ $t('system.no_data') }}.
            </span>
          </template>
        </InfiniteLoading>
      </div>
    </div>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import SystemActivity from '@/components/activity/system/System.vue';
  import SystemPolicyActivity from '@/components/activity/system/SystemPolicy.vue';
  import SystemNetworkActivity from '@/components/activity/system/SystemNetwork.vue';
  import SystemLogManagementActivity from '@/components/activity/system/SystemLogManagement.vue';
  import SystemDataObjectActivity from '@/components/activity/system/SystemDataObject.vue';
  import SystemDataDiscoveryActivity from '@/components/activity/system/SystemDataDiscovery.vue';
  import SystemAccessControlActivity from '@/components/activity/system/SystemAccessControl.vue';
  import SystemThirdPartyActivity from '@/components/activity/system/SystemThirdParty.vue';
  import SystemValidationActivity from '@/components/activity/system/SystemValidation.vue';
  import SystemRiskScenarioActivity from '@/components/activity/system/SystemRiskScenario.vue';
  import SystemMeasureActivity from '@/components/activity/system/SystemMeasure.vue';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'SystemHistory',
    data: function() {
      return {
        system: {},
        items: [],
        currentPage: 1,
        totalRows: 0,
        page: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      SystemActivity,
      SystemPolicyActivity,
      SystemNetworkActivity,
      SystemLogManagementActivity,
      SystemDataObjectActivity,
      SystemDataDiscoveryActivity,
      SystemAccessControlActivity,
      SystemThirdPartyActivity,
      SystemValidationActivity,
      InfiniteLoading,
      SystemRiskScenarioActivity,
      SystemMeasureActivity
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      loadingHandler($state) {
        let self = this
        piincHttp.get('activity-log', { params:
          {
            log: this.system.who_am_i + '::' + this.system.id,
            page: this.page,
            client: self.getClient.slug
          }
        }).then(({ data }) => {
          if (data.items.length) {
            self.page += 1;
            self.items.push(...data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
      }
    },
    created () {
      this.system = this.object;
    }
  }
</script>
